<template>
  <CRow>
    <CCol col="12" lg="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <strong>{{ $lang.business_traveller.crud.create }}</strong>
        </CCardHeader>
        <CForm novalidate @submit.prevent="onSubmit">
          <CCardBody>
            <CRow>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.business_traveller.form.user }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="userOptions"
                      v-model="business_traveller.user"
                  ></v-select>
                  <small
                      class="error"
                      v-if="
                      $v.business_traveller.user.$error &&
                      !$v.business_traveller.user.required
                    "
                  >
                    {{ $lang.business_traveller.validation.required.user }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.business_traveller.form.area }}
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.business_traveller.form.area"
                      class="form-control"
                      item-text="title"
                      item-value="id"
                      v-model="business_traveller.area"
                  />
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.business_traveller.form.industry }}
                    <required_span/>
                  </label>
                  <v-select
                      multiple
                      :options="industryOptions"
                      v-model="business_traveller.industry"
                      @option:selected="getSubIndustry"
                  >
                  </v-select>
                  <small
                      class="error"
                      v-if="
                      $v.business_traveller.industry.$error &&
                      !$v.business_traveller.industry.required
                    "
                  >
                    {{ $lang.business_traveller.validation.required.industry }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.business_traveller.form.subIndustry }}
                    <required_span/>
                  </label>
                  <v-select
                      multiple
                      :options="subIndustryOptions"
                      v-model="business_traveller.subIndustry"
                  ></v-select>
                  <small
                      class="error"
                      v-if="
                      $v.business_traveller.subIndustry.$error &&
                      !$v.business_traveller.subIndustry.required
                    "
                  >
                    {{
                      $lang.business_traveller.validation.required.subIndustry
                    }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.business_traveller.form.country }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="countryOptions"
                      v-model="business_traveller.country"
                      @option:selected="loadState"
                  >
                  </v-select>
                  <small
                      class="error"
                      v-if="
                      $v.business_traveller.country.$error &&
                      !$v.business_traveller.country.required
                    "
                  >
                    {{ $lang.business_traveller.validation.required.country }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div
                    class="form-group"
                    :class="{
                    'form-group--error': $v.business_traveller.state.$error,
                  }"
                >
                  <label class="form__label"
                  >{{ $lang.business_traveller.form.state }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="StateOptions"
                      v-model="business_traveller.state"
                      @option:selected="loadCity"
                  >
                  </v-select>
                  <small
                      class="error"
                      v-if="
                      $v.business_traveller.state.$error &&
                      !$v.business_traveller.state.required
                    "
                  >{{
                      $lang.business_traveller.validation.required.state
                    }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div
                    class="form-group"
                    :class="{
                    'form-group--error': $v.business_traveller.city.$error,
                  }"
                >
                  <label class="form__label"
                  >{{ $lang.business_traveller.form.city }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="cityOptions"
                      v-model="business_traveller.city"
                  >
                  </v-select>
                  <small
                      class="error"
                      v-if="
                      $v.business_traveller.city.$error &&
                      !$v.business_traveller.city.required
                    "
                  >{{
                      $lang.business_traveller.validation.required.city
                    }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.business_traveller.form.fromDate }}
                    <required_span/>
                  </label>
                  &nbsp;
                  <!--                            <datepicker :format="getHumanDate"  v-model="business_traveller.fromDate"></datepicker>-->
                  <input type="date" v-model="business_traveller.fromDate"/>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.business_traveller.form.toDate }}
                    <required_span/>
                  </label>
                  &nbsp;
                  <input type="date" v-model="business_traveller.toDate"/>
                  <!--                            <datepicker :format="getHumanDate"  v-model="business_traveller.toDate"></datepicker>-->
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.business_traveller.form.isActive }} </label
                  >&nbsp; &nbsp;
                  <input
                      type="checkbox"
                      :placeholder="$lang.business_traveller.form.isActive"
                      :value="1"
                      @change="changedIsVerified"
                      v-model="business_traveller.isActive"
                  />
                </div>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton
                type="submit"
                size="sm"
                color="primary"
                :disabled="submitted"
            >
              <CIcon name="cil-check-circle"/>
              {{ $lang.buttons.general.crud.submit }}
            </CButton>
            <CButton color="danger" size="sm" @click="cancel">
              <CIcon name="cil-ban"/>
              {{ $lang.buttons.general.crud.cancel }}
            </CButton>
          </CCardFooter>
        </CForm>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  businessTraveller,
  industry,
  users,
  profileSubIndustry,
  country,
  state,
  city,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import VueToasted from "vue-toasted";
import moment from "moment";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "BusinessTravellerCreate",
  mixins: [Mixin],
  components: {
    required_span,
  },
  data() {
    return {
      submitted: false,
      Disabled: true,
      err_msg: "",
      profileImage: "",
      dismissCountDown: 0,
      selectedfromDate: "",
      selectedtoDate: "",
      industryOptions: [],
      subIndustryOptions: [],
      selectedIndustry: [],
      countryOptions: [],
      StateOptions: [],
      cityOptions: [],
      statusOptions: [],
      userOptions: [],
      getSubIndustries: [],
      getIndustries: [],
      self: this,
      module: businessTraveller,
      moduleIndustry: industry,
      moduleSubIndustry: profileSubIndustry,
      moduleUser: users,
      moduleCountry: country,
      moduleState: state,
      moduleCity: city,
      business_traveller: {
        area: "",
        fromDate: "",
        toDate: "",
        industry: "",
        subIndustry: "",
        country: "",
        state: "",
        city: "",
        user: "",
        status: "",
        isActive: "",
      },
    };
  },
  computed: {},
  validations: {
    business_traveller: {
      industry: {
        required,
      },
      subIndustry: {
        required,
      },
      user: {
        required,
      },
      country: {
        required,
      },
      state: {
        required,
      },
      city: {
        required,
      },
      fromDate: {
        required,
      },
      toDate: {
        required,
      },
    },
  },
  mounted() {
    const statusType = {
      PENDING: "Pending",
      VERIFIED: "Verified",
      BLOCK: "Block",
    };
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.statusOptions.push(
        {value: 1, label: statusType.PENDING},
        {value: 2, label: statusType.VERIFIED},
        {value: 3, label: statusType.BLOCK}
    );
    self.dismissCountDown = 0;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;

    axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
      response.data.data.map(function (value, key) {
        // self.industryLists.push({value: value.id, label: value.title});
        if (value.industryId === "") {
          self.industryOptions.push({value: value.id, label: value.industry});
        }
      });
    });

    axios.get(this.listUrlApi(this.moduleUser)).then((response) => {
      response.data.map(function (value, key) {
        self.userOptions.push({value: value.id, label: value.username});
      });
    });

    axios.get(this.listUrlApi(this.moduleCountry)).then((response) => {
      response.data.data.map(function (value, key) {
        self.countryOptions.push({value: value.id, label: value.name});
      });
    });
  },
  methods: {
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    loadState() {
      let self = this;
      const postData = {countryId: self.business_traveller.country.value};
      axios
          .post(this.listUrlApi(this.moduleState), postData)
          .then((response) => {
            self.StateOptions = [];
            response.data.data.map(function (value, key) {
              self.StateOptions.push({value: value.id, label: value.name});
            });
          });
    },
    loadCity() {
      let self = this;
      const postData = {stateId: self.business_traveller.state.value};
      axios
          .post(this.listUrlApi(this.moduleCity), postData)
          .then((response) => {
            self.cityOptions = [];

            response.data.data.map(function (value, key) {
              self.business_traveller.city = {
                value: value.id,
                label: value.name,
              };
              self.cityOptions.push({value: value.id, label: value.name});
            });
          });
    },
    changedIsVerified(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.business_traveller.isActive = 1;
      } else {
        self.business_traveller.isActive = 0;
      }
    },
    getSubIndustry() {
      let self = this;
      let getIndustries = [];
      this.business_traveller.industry.map(function (value, key) {
        getIndustries[key] = value.value;
      });
      const postData = {parentId: getIndustries, array: true};
      axios
          .post(this.listUrlApi(this.moduleIndustry), postData)
          .then((response) => {
            self.subIndustryOptions = [];
            response.data.data.map(function (value, key) {
              if (value.industryId > 0) {
                self.subIndustryOptions.push({
                  value: value.id,
                  label: value.SubIndustry,
                });
              }
            });
          });
    },
    sendAlert() {
      this.$root.$emit("alert", "test1", "test2");
    },
    onSubmit() {
      let self = this;
      this.$v.business_traveller.$touch();
      if (this.$v.business_traveller.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        if (self.business_traveller.fromDate !== "") {
          self.selectedfromDate = moment(
              self.business_traveller.fromDate,
              "YYYY-MM-DD"
          ).format("YYYY-MM-DD");
        }
        if (self.business_traveller.toDate !== "") {
          self.selectedtoDate = moment(
              self.business_traveller.toDate,
              "YYYY-MM-DD"
          ).format("YYYY-MM-DD");
        }
        setTimeout(() => {
          let username;
          self.business_traveller.subIndustry.map(function (value, key) {
            self.getSubIndustries.push({
              id: value.value,
            });
          });
          self.business_traveller.industry.map(function (value, key) {
            self.getIndustries.push({
              id: value.value,
            });
          });
          if (self.business_traveller.user.value !== "") {
            username = self.business_traveller.user.value;
          }

          const postData = {
            userId: username,
            fromDate: self.selectedfromDate,
            toDate: self.selectedtoDate,
            area: self.business_traveller.area,
            country: self.business_traveller.country.value,
            state: self.business_traveller.state.value,
            city: self.business_traveller.city.value,
            industry: self.getIndustries,
            subindustry: self.getSubIndustries,
            isActive: self.business_traveller.isActive,
          };
          axios
              .post(this.createUrlWeb(this.module), postData)
              .then(function (response) {
                self.submitted = false; //Enable Button
                if (response.data.code === 200) {
                  localStorage.setItem("showAlert", "This is my alert");
                  self.sendAlert();
                  self.$router.push({path: "/business-traveller"});
                } else {
                  self.err_msg = response.data.response.message;
                  self.dismissCountDown = 10;
                }
              })
              .catch(function () {
                self.submitted = false; //Enable Button
              });
        }, 500);
      }
    },
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "/business-traveller"});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
